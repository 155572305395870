import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Fab } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CertificateOfCompletion from "../../../../Consumer/components/CertificateOfCompletion/CertificateOfCompletion";
import ShareSpeedDial from "../../../../Consumer/components/ShareSpeedDial/ShareSpeedDial";
import { fetchDocumentFromFireStore } from "../../../../Consumer/supportFunctions/FirebaseFunctions";
import { formatTimestamp } from "../../../../Consumer/supportFunctions/GeneralHelperFunctions";
import incomplete from "../../../assets/Images/incomplete.webp";
import useIsTabletSize from "../../../customHooks/isTabletSizeHook";
import OnlineTestReportDetailModal from "../../Modals/ReportsModals/OnlineTestReportDetailModal";
import ReportOverAllSummaryTextComponent from "../../Modals/ReportsModals/ReportOverAllSummaryTextComponent";
import "../ReportComponents.css";
import "./OnlineTestReportComponent.css";
import OnlineTestReportRoundsListItemComp from "./OnlineTestReportRoundsListItemComp";

ChartJS.register(ArcElement, Tooltip, Legend);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="w-80"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ReportForASingleOnlineTest({
  onlineTestRecord,
  onlineTestId,
  showShare = false,
  showBackBtn = false,
}) {
  ReportForASingleOnlineTest.propTypes = {
    onlineTestRecord: PropTypes.object.isRequired,
    onlineTestId: PropTypes.string.isRequired,
    showShare: PropTypes.bool,
    showBackBtn: PropTypes.bool,
  };
  const { user } = useSelector((state) => ({ ...state }));
  const childRef = useRef(null);
  const isTabletSize = useIsTabletSize();
  const [dataSourceForModel, setDataSourceForModel] = useState([]);
  const [indexForModel, setIndexForModel] = useState(0);
  const [showCertificate, setShowCertificate] = useState(false);
  const [allRoundsReviewsArray, setAllRoundsReviewsArray] = useState([]);
  const [detailedRoundsData, setDetailedRoundsData] = useState([]);
  const [
    shouldOpenNonCodingRoundReviewModel,
    setShouldOpenNonCodingRoundReviewModel,
  ] = React.useState(false);

  useEffect(() => {}, []);

  const fabStyle = {
    backgroundColor: "rgb(220, 53, 69)",
    "&:hover": {
      backgroundColor: "rgb(187, 45, 59)",
    },
  };

  const formattedTimeStamps = formatTimestamp(onlineTestRecord?.timestamp);
  const formattedDateBig = formattedTimeStamps.formattedDateBig;
  const formattedDate = formattedTimeStamps.formattedDate;

  useEffect(() => {
    const fetchQuestionsData = async () => {
      const newData = await Promise.all(
        onlineTestRecord.allRoundsReportData.map(async (round) => {
          const newRoundQuestions = await Promise.all(
            Object.keys(round.roundQuestions).map(async (questionId) => {
              const questionData = await fetchDocumentFromFireStore({
                parent_collection: `${round.roundTitle}_question_bank`,
                parent_document: questionId,
              });
              if (!questionData) {
                return null;
              }
              return {
                [questionId]: {
                  userChosenOption: round.roundQuestions[questionId],
                  correctAnswerIndex: questionData.correctAnswerIndex,
                  question: questionData.question,
                  options: questionData.options,
                },
              };
            })
          );

          return {
            ...round,
            roundQuestions: Object.assign({}, ...newRoundQuestions),
          };
        })
      );
      setDetailedRoundsData(newData);
    };
    if (onlineTestRecord?.isThisEntirelyDone) {
      fetchQuestionsData();
    }
  }, [allRoundsReviewsArray]);

  useEffect(() => {
    console.log("DWaraka Siri onlineTestRecord: ", onlineTestRecord);
    if (onlineTestRecord?.isThisEntirelyDone) {
      const normalRoundsArray = onlineTestRecord.allRoundsReportData;
      const normalRoundTitlesArray = normalRoundsArray.map(
        (round) => `${round.roundTitle}Round`
      );
      setAllRoundsReviewsArray(normalRoundTitlesArray);
    }
  }, [onlineTestRecord, onlineTestId]);

  const handleDownloadCertificate = () => {
    toast.warning("Download in progress...");
    setShowCertificate(true);
    const loadingTimeout = setTimeout(async () => {
      if (childRef.current) {
        await childRef.current.handlePdfDownlod();
      }
    }, 1000); // Change the timeout value to match your actual loading time

    return () => {
      clearTimeout(loadingTimeout); // Clean up the timeout when the component unmounts
    };
  };

  return (
    <div>
      {showCertificate ? (
        <>
          <CertificateOfCompletion
            ref={childRef}
            name={user.displayName}
            score={onlineTestRecord.totalCorrectAnswers}
            date={formattedDateBig}
            role={onlineTestRecord.sdeLevel}
            setShowCertificate={setShowCertificate}
          />
        </>
      ) : (
        <TabPanel
          className="reports_page"
          key={0}
          value={0}
          index={0}
          style={{ width: "100%" }}
        >
          <div className="d-flex justify-content-between mb-2 mt-0 align-items-center">
            {showCertificate && (
              <button
                className="report_card_heading text-decoration-none resume-evaluaitor-btn px-2"
                onClick={handleDownloadCertificate}
              >
                <WorkspacePremiumIcon className="mb-1" /> Download Certifcate
              </button>
            )}
            {showShare && (
              <div style={{ height: "50px", zIndex: "20" }}>
                <ShareSpeedDial
                  shareUrl={`https://evaluaitor.in/report/${user.profileId}/${
                    onlineTestRecord.id || onlineTestId
                  }`}
                  shareTitle={
                    "I'm excited to showcase my AI mock interview results on the EvaluAItor website. Check them out at"
                  }
                />
              </div>
            )}
            {showBackBtn && (
              <button
                className="close-round-btn"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Fab color="primary" aria-label="home" sx={fabStyle}>
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </Fab>
              </button>
            )}
          </div>

          <div className="row justify-content-evenly">
            {onlineTestRecord?.isThisEntirelyDone && (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <ReportOverAllSummaryTextComponent
                      formattedDate={formattedDate}
                      roundScore={onlineTestRecord?.totalCorrectAnswers}
                      incorrectQuestions={
                        onlineTestRecord?.totalIncorrectAnswers
                      }
                      unattemptedQuestions={onlineTestRecord?.totalUnattempted}
                      proctoringData={onlineTestRecord?.proctoringData}
                      recordedUrl={onlineTestRecord?.recordedUrl}
                      onlineTestId={onlineTestId || onlineTestRecord?.id}
                      userName={user.displayName}
                    />
                  </div>
                  <div
                    className="col-lg-6"
                    style={{ height: isTabletSize ? "300px" : "150px" }}
                  >
                    <Doughnut
                      data={{
                        labels: ["Correct", "Incorrect", "Unattempted"],
                        datasets: [
                          {
                            data: [
                              onlineTestRecord.totalCorrectAnswers,
                              onlineTestRecord.totalIncorrectAnswers,
                              onlineTestRecord.totalUnattempted,
                            ],
                            backgroundColor: [
                              "rgba(75, 192, 192, 0.2)",
                              "rgba(255, 99, 132, 0.2)",
                              "rgba(255, 206, 86, 0.2)",
                            ],
                            borderColor: [
                              "rgba(75, 192, 192, 1)",
                              "rgba(255, 99, 132, 1)",
                              "rgba(255, 206, 86, 1)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </div>
                </div>{" "}
                <hr className="my-2" />
              </>
            )}
            {onlineTestRecord?.isThisEntirelyDone ? (
              onlineTestRecord?.allRoundsReportData?.map((roundData) => (
                <div className="col-lg-4 col-sm-12" key={roundData.roundTitle}>
                  <div
                    className={`score_card ${
                      !roundData.isQualified
                        ? "border border-danger"
                        : "border border-success"
                    }`}
                  >
                    <div>
                      <div className="score_card_name">
                        {roundData.roundTitle.split("_").slice(1).join(" ")}
                      </div>
                      <div className="row">
                        <div className=" col-8 pe-2 d-flex justify-content-center">
                          <div className="CircularProgressbardiv">
                            <div className="chart easyPieChart">
                              <Doughnut
                                data={{
                                  labels: [
                                    "Correct",
                                    "Incorrect",
                                    "Unattempted",
                                  ],
                                  datasets: [
                                    {
                                      data: [
                                        roundData.roundScore,
                                        roundData.incorrectQuestions,
                                        roundData.unattemptedQuestions,
                                      ],
                                      backgroundColor: [
                                        "rgba(75, 192, 192, 0.2)",
                                        "rgba(255, 99, 132, 0.2)",
                                        "rgba(255, 206, 86, 0.2)",
                                      ],
                                      borderColor: [
                                        "rgba(75, 192, 192, 1)",
                                        "rgba(255, 99, 132, 1)",
                                        "rgba(255, 206, 86, 1)",
                                      ],
                                      borderWidth: 1,
                                    },
                                  ],
                                }}
                                options={{
                                  plugins: {
                                    legend: {
                                      display: false, // Hide the legend
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="scoreCnt tooltipped score col-4"
                          data-position="bottom"
                          data-tooltip="Longest streak/Global longest streak"
                          style={{ color: "#5e6d9e" }}
                        >
                          <span
                            className="numerator"
                            style={{
                              color: "#5e6d9e",
                              borderColor: "#5e6d9e",
                            }}
                          >
                            {roundData?.roundScore || 0}
                          </span>
                          <span className="denominator">
                            {Object.keys(roundData?.roundQuestions).length}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <img
                  src={incomplete}
                  alt="Not Completed"
                  className="w-50 mt-4"
                />
              </>
            )}
          </div>
          <hr
            style={{
              border: "1px solid #EAEBEF",
              margin: "20px 0px",
            }}
          />
          {onlineTestRecord?.isThisEntirelyDone &&
            onlineTestRecord.allRoundsReportData.map((roundData, index) => (
              <OnlineTestReportRoundsListItemComp
                key={roundData.roundTitle}
                roundData={roundData}
                index={index}
                setIndexForModel={setIndexForModel}
                setDataSourceForModel={setDataSourceForModel}
                detailedRoundsData={detailedRoundsData}
                formattedDate={formattedDate}
                setShouldOpenNonCodingRoundReviewModel={
                  setShouldOpenNonCodingRoundReviewModel
                }
              />
            ))}
          {shouldOpenNonCodingRoundReviewModel &&
            OnlineTestReportDetailModal(
              shouldOpenNonCodingRoundReviewModel,
              setShouldOpenNonCodingRoundReviewModel,
              indexForModel,
              dataSourceForModel
            )}
        </TabPanel>
      )}
    </div>
  );
}
