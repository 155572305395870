import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AlertModal from "../../../Business/components/Modals/AlertModal/AlertModal";
import BusinessNavBar from "../../../Business/layouts/BusinessNavBar/BusinessNavBar";
import { fetchAllBusinessPageData } from "../../../Business/utils/FirebaseFunctions/realtimeDBFunctions";
import { logout } from "../../../Business/utils/authFunctions";
import GoogleSignInComponent from "../../../Global/components/Authentication/GoogleSignInComponent/GoogleSignInComponent";
import {
  capitalizeFirstLetter,
  cleanEmailAddress,
} from "../../../Global/utils/stringExtensionFunctions";
import { auth } from "../../../firebase";
import { useDynamicPageData } from "../../customHooks/useDynamicPageData";
import defaultPageData from "../../data/WebsiteData/Business-admin/loginPage.json";
import { findIfValidBusinessUser } from "../../utils/FirebaseFunctions/realtimeDBFunctions";
import { getSiteBusinessName } from "../../utils/stringExtensionFunction";
import "./LoginPage.css";
export default function LoginPage() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pageData, setPageData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);
  const dynamicPageData = useDynamicPageData("loginPage.json", defaultPageData);
  const { redirectPage } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    const getPageData = async () => {
      setIsLoading(true);
      const data = await fetchAllBusinessPageData({
        realTimeDBPath: `${capitalizeFirstLetter(
          getSiteBusinessName().split("-")[0]
        )}_all_pages_data`,
        categoryName: "landing_page",
      });
      console.log("DWaraka Siri all pages data: ", data);
      setPageData(data);
      setIsLoading(false);
    };
    getPageData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let isValidUser = await findIfValidBusinessUser(
        email,
        `${capitalizeFirstLetter(getSiteBusinessName())}_candidates_list`
      );
      if (!isValidUser) {
        setShowAlertModal(true);
        setIsLoading(false);
        return;
      }
      await signInWithEmailAndPassword(
        auth,
        cleanEmailAddress(email),
        password
      );
      setIsLoading(false);
      navigate(redirectPage);
    } catch (error) {
      console.log("DWaraka Siri login error: ", error.code, error.message);

      if (error.code === "auth/invalid-login-credentials") {
        setIsInvalidCredentials(true);
        toast.error("Invalid email or password. Please try again.");
      } else {
        toast.error(error.code);
      }
      setIsLoading(false);
    }
  };
  return (
    <div className="tyn-root business-login">
      {!isLoading && (
        <BusinessNavBar sticky={true} landingPageData={pageData} />
      )}
      <div className="d-lg-flex half">
        <div className="contents">
          <div className="main-row row align-items-center justify-content-center w-100">
            <div
              className="background-img col-md-6 row h-100 d-none d-lg-flex align-items-center justify-content-center"
              style={{
                backgroundImage: `url(${dynamicPageData.leftHalf.bgImg})`,
              }}
            >
              <div className="col-lg-7 col-11 bg-light pb-5 pt-4 px-4 rounded  text-center">
                <img
                  className="login-logo rounded"
                  src={pageData.logo}
                  alt="logo"
                />
                <h3 className="text-center">{pageData.companyName}</h3>
                <p className="mb-4 text-justify">
                  {pageData.companyDescription}
                </p>
                {pageData.cta && (
                  <button className="btn btn-primary btn-sm m-auto">
                    {dynamicPageData.leftHalf.ctaTitle}
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-6 row justify-content-center">
              <div className="col-lg-7 col-11">
                <div className="text-center">
                  <h3>{pageData.companyName}</h3>
                  <p className="mb-4">
                    Login to your Admin account to view results
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group first mb-3">
                    <label htmlFor="username">Email</label>{" "}
                    {!isEmailValid && (
                      <small className="text-danger">
                        (please enter a valid email)
                      </small>
                    )}
                    <input
                      onChange={(e) => {
                        setIsInvalidCredentials(false);
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(e.target.value)) {
                          setIsEmailValid(false);
                        } else {
                          setIsEmailValid(true);
                        }
                        setEmail(e.target.value);
                      }}
                      value={email}
                      autoFocus
                      required
                      type="email"
                      className="form-control my-2"
                      id="email-address"
                      placeholder="your-email@gmail.com"
                    />
                  </div>
                  <div className="form-group last mb-3">
                    <label className="form-label d-flex" htmlFor="password">
                      Password{" "}
                      <Link
                        to="/forgot/password"
                        className="link link-primary ms-auto"
                      >
                        Forgot Password ?
                      </Link>{" "}
                    </label>
                    <input
                      type="password"
                      required
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => {
                        setIsInvalidCredentials(false);
                        setPassword(e.target.value);
                      }}
                      placeholder="Your Password"
                    />
                  </div>
                  {isInvalidCredentials && (
                    <div className="text-center">
                      <small className="text-danger ps-2">
                        Invalid credentials. Please try again
                      </small>
                    </div>
                  )}
                  <button
                    disabled={!isEmailValid || !email || !password || isLoading}
                    onClick={handleSubmit}
                    type="submit"
                    defaultValue="Log In"
                    className="btn w-100 btn-primary mt-4"
                  >
                    Log In
                  </button>
                </form>
                {dynamicPageData.rightHalf.showRegisterOption && (
                  <div className="text-center mt-4">
                    <p className="small mb-0">
                      Don&apos;t have an account?{" "}
                      <Link to="/register">Register</Link>
                    </p>
                  </div>
                )}
                {dynamicPageData.rightHalf.showGooglSignIn && (
                  <>
                    <div className="or_login_ng-star-inserted mt-3">
                      <span className="or_login_ng-star-inserted-span">
                        Or login with google account
                      </span>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="form-group">
                        <GoogleSignInComponent
                          setLoading={setIsLoading}
                          callbackFunc={async ({ userEmail }) => {
                            let isValidUser = await findIfValidBusinessUser(
                              userEmail,
                              `${capitalizeFirstLetter(
                                getSiteBusinessName()
                              )}_candidates_list`
                            );
                            if (!isValidUser) {
                              setShowAlertModal(true);
                              logout({ dispatch, navigate, location });
                              setIsLoading(false);
                              return;
                            }
                            dispatch({
                              type: "LOGIN_REDIRECT",
                              payload: "/",
                            });
                            toast.success(`Login successfull`);
                            navigate("/");
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {showAlertModal && (
          <AlertModal
            show={showAlertModal}
            onReject={() => setShowAlertModal(false)}
            onPrimaryBtnClick={() => {
              setShowAlertModal(false);
              window.location.href = "https://evaluaitor.in/";
            }}
            text={"You don't have access. Please contact us."}
            heading={"Private content"}
            primaryBtnText={"Explore EvaluAItor"}
          />
        )}
      </div>
    </div>
  );
}
